/* -----------------------------------------------------------------------------
 *
 * Components: Hero Address Check
 *
 * ----------------------------------------------------------------------------- */

@import '../../../../styles/utilities/common.css';


.component {
  @media (--medium) {
    background-position-x: 100%;
  }
}

/* override */
.component .component__wrapper {
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.component .component__layout {
  padding-right: 0;
  padding-left: 0;
}

.component__ctaContainer {
  max-width: 800px;
  margin-left: 35px;
  padding-right: 35px;

  @media (--medium) {
    margin-left: 50px;
  }

  @media (--xlarge) {
    margin-left: 165px;
  }

  @media (min-width: 1600px) {
    margin-left: 345px;
  }
}

.component__mobileBackground {
  width: 376px;
  margin-right: auto;
  margin-left: auto;
  transform: none;
}
