.component__modalContainer {
  width: var( --grid-width-default );
  padding: 0;
  border-radius: 24px;
}

.component__close {
  position: absolute;
  right: 0;
  margin: 26px 27px 0 0 ;
}

.component__close::before {
  font-size: 24px;
  font-weight: 700;
}

.component__contentWrapper {
  text-align: center;
  padding: 80px 0;

  @media (--medium-max) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.component__headerIcon {
  margin-bottom: 20px;
  color: var( --color-torch-red );
  font-size: 40px;
}

.component__heading {
  margin: 0 0 16px 0;
  padding: 0;

  @media (--medium) {
    font-size: rem( 30px );
    line-height: lh( 30px, 34px );
  }

  :global(.tooltip-btn) {
    font-size: 60%;

    @media (--medium) {
      font-size: 40%;
    }
  }
}

.component__subheading {
  font-weight: 500;

  :global(.tooltip-btn) {
    font-size: 80%;
  }
}

.component__addressWrapper {
  margin: 42px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;

  @media (--medium-max) {
    flex-wrap: wrap;
    margin: 34px 0;
  }
}

.component__icon {
  font-size: 24px;
  margin-right: 8px;
}

.component__address {
  margin: 0 0 0 8px;
  text-transform: uppercase;
  
  @media (--medium-max) {
    margin: 10px 0 0;
  }
}

.component__buttonWrapper {
  width: 265px;
  margin: 0 auto;
}