/* -----------------------------------------------------------------------------
 *
 * Component: Split Section
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/utilities/common.css';

.component {
  display: flex;
  position: relative;
  flex-flow: column-reverse;
  padding: 0;
  overflow-x: hidden;
}

.componentMobileReverse {
  flex-flow: column;
}

/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */

.component__content {
  padding: 60px 0;

  @media (--large) {
    padding: 80px 0;
    padding-left: 32.5px;
  }
}

.component__contentReverse {
  @media (--large) {
    padding-right: 32.5px;
    padding-left: 0;
  }
}

.component__copyContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.component__copy {
  margin-bottom: 0;
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.component__title {
  margin-bottom: 15px;
  padding: 0;
  font-weight: 700;
}

.component__title span {

  @media (--medium) {
    font-size: rem( 35px );
    line-height: lh( 35px, 50px );
  }
}

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.component__subtitle {
  margin-bottom: 30px;
}

/* -----------------------------------------------------------------------------
 * Item heading legal
 * ----------------------------------------------------------------------------- */

.component__itemHeading sup {
  position: relative;
  top: -10px;
  font-size: 50%;
}

/* -----------------------------------------------------------------------------
 * List
 * ----------------------------------------------------------------------------- */

.component__list {
  margin-bottom: 30px;
  padding: 0;
  list-style-type: none;
  line-height: lh( 16px, 20px );
}

.component__list li {
  position: relative;
  margin-bottom: 20px;
}

/* -----------------------------------------------------------------------------
 * Icon
 * ----------------------------------------------------------------------------- */

.component__icon {
  margin-right: 10px;
  color: var( --color-primary );
  font-size: rem( 17px );
}

.component__icon__red,
.component__icon__dark {
  color: var( --color-electric-blue );
}

/* -----------------------------------------------------------------------------
 * Legal
 * ----------------------------------------------------------------------------- */

.component__legal {
  margin-bottom: 40px;
}

/* -----------------------------------------------------------------------------
 * Legal
 * ----------------------------------------------------------------------------- */

.component__buttonStyle {
  @media (--large) {
    width: 100%;
  }

  @media (--hd) {
    width: 48%;
  }
}

/* -----------------------------------------------------------------------------
 * Background Wrap
 * ----------------------------------------------------------------------------- */

.component__backgroundWrap {
  @mixin backgroundLeft;

  right: 0;
  overflow: hidden;
}


.component__backgroundWrapReverse {
  left: 0;
}

.component__backgroundWrapContain img {
  object-fit: contain !important;
}

/*
.component__backgroundWrapMobile {
  display: block;
}

.component__backgroundWrap img {
  @media (--xlarge) {
    object-fit: contain;
  }
} */

/* -----------------------------------------------------------------------------
 * Background
 * ----------------------------------------------------------------------------- */

.component__background {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.component__background_img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -----------------------------------------------------------------------------
 * Background Mobile
 * ----------------------------------------------------------------------------- */

.component__backgroundMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;

  @media (--small) {
    height: 414px;
  }

  @media (--medium) {
    display: none;
  }
}
