/* -----------------------------------------------------------------------------
 *
 * Components: FloatingPanel
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/utilities/common.css';

.component {
  padding-top: 30px;
  padding-bottom: 22px;
  background-color: var( --color-white );
  background-repeat: no-repeat;
  background-position: bottom 38px right 0;
  background-size: 100% 44%;

  @media (--medium) {
    background-position: bottom 88px right 0;
    background-size: 100% 46%;
  }

  @media (--large) {
    background-position: bottom 93px right 0;
  }

  @media (--hd) {
    padding-bottom: 40px;
    background-position: bottom -8px right 0;
    background-size: 100vw 112%;
  }
}

/* -----------------------------------------------------------------------------
 * Row
 * ----------------------------------------------------------------------------- */
.row {
  @media (--medium) {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }

  @media (--hd) {
    align-items: center;
    justify-content: space-between;
  }
}

/* -----------------------------------------------------------------------------
 * Panel
 * ----------------------------------------------------------------------------- */

.panel {
  width: 100%;
  margin-bottom: 6px;
  padding: 40px 24px;
  border-radius: 32px;
  background-color: var( --color-midnight-express );
  color: var( --color-white );

  @media (--medium) {
    padding: 48px;
  }

  @media (--hd) {
    max-width: 600px;
    margin-bottom: 0;
    padding: 88px 48px;
  }
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.title {
  padding-top: 0;
  font-size: rem( 30px );
  font-weight: 700;
  line-height: lh( 30px, 34px );

  @media (--medium) {
    margin-bottom: 28px;
  }

  @media (--hd) {
    margin-bottom: 8px;
    font-size: rem( 56px );
    line-height: lh( 56px, 60px );
  }
}

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.subtitle {
  font-size: rem( 18px );
  font-weight: 700;
  line-height: lh( 18px, 22px );

  @media (--medium) {
    margin-bottom: 28px;
  }
}

/* -----------------------------------------------------------------------------
 * UL
 * ----------------------------------------------------------------------------- */

.ul {
  list-style-type: none;
}

/* -----------------------------------------------------------------------------
 * LI
 * ----------------------------------------------------------------------------- */

.li {
  position: relative;
  margin-bottom: 16px;
  font-size: rem( 16px );
  font-weight: normal;
  letter-spacing: 0;
  line-height: lh( 16px, 20px );
}

.li:last-of-type {
  margin-bottom: 40px;
}

.li::before {
  @mixin icomoon;

  content: var( --icon-check-short );
  position: absolute;
  top: 1px;
  margin-left: -30px;
  color: var( --color-electric-blue );
  font-size: rem( 18px );
}

/* -----------------------------------------------------------------------------
 * Image Container
 * ----------------------------------------------------------------------------- */

.imageContainer {

  @media (--hd) {
    transform: translateX( 100px );
  }
}
